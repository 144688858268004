/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import ModelModel from "@/interfaces/Models/model";
import LMIModel from "@/interfaces/Models/lmi";
import ConfigurationModel from "@/interfaces/Models/configuration";
import TypeModel from "@/interfaces/Models/type";
import CompositionModel from "@/interfaces/Models/composition";
import InputModel from "@/interfaces/Models/input";


export default new class extends StackExchangeService {
    async showModels(): Promise<SuccessResponse<ModelModel[]>> {
        return await this.fetch("compositions/models");
    }

    async showLMIForModel(modelId: number): Promise<SuccessResponse<LMIModel[]>> {
        return await this.fetch("compositions/lmi", {
            "filter_groups[0][filters][0][key]" : "model_id",
            "filter_groups[0][filters][0][operator]" : "eq",
            "filter_groups[0][filters][0][value]" : modelId
        });
    }

    async showConfigurationsForModelWithinLMI(modelId: number, LMIId: number): Promise<SuccessResponse<ConfigurationModel[]>> {
        return await this.fetch("compositions/configurations", {
            "filter_groups[0][filters][0][key]" : "model_id",
            "filter_groups[0][filters][0][operator]" : "eq",
            "filter_groups[0][filters][0][value]" : modelId,
            "filter_groups[0][filters][1][key]" : "lmi_id",
            "filter_groups[0][filters][1][operator]" : "eq",
            "filter_groups[0][filters][1][value]" : LMIId
        });
    }

    async showTypesForModelWithinLMIConfiguration(modelId: number, LMIId: number, configurationId: number): Promise<SuccessResponse<TypeModel[]>> {
        return await this.fetch("compositions/types", {
            "filter_groups[0][filters][0][key]" : "model_id",
            "filter_groups[0][filters][0][operator]" : "eq",
            "filter_groups[0][filters][0][value]" : modelId,
            "filter_groups[0][filters][1][key]" : "lmi_id",
            "filter_groups[0][filters][1][operator]" : "eq",
            "filter_groups[0][filters][1][value]" : LMIId,
            "filter_groups[0][filters][2][key]" : "configuration_id",
            "filter_groups[0][filters][2][operator]" : "eq",
            "filter_groups[0][filters][2][value]" : configurationId
        });
    }

    async showForModelLMIConfigurationType(modelId: number, LMIId: number, configurationId: number, typeId: number): Promise<SuccessResponse<CompositionModel>> {
        return await this.fetch("compositions/first", {
            "filter_groups[0][filters][0][key]" : "model_id",
            "filter_groups[0][filters][0][operator]" : "eq",
            "filter_groups[0][filters][0][value]" : modelId,
            "filter_groups[0][filters][1][key]" : "lmi_id",
            "filter_groups[0][filters][1][operator]" : "eq",
            "filter_groups[0][filters][1][value]" : LMIId,
            "filter_groups[0][filters][2][key]" : "configuration_id",
            "filter_groups[0][filters][2][operator]" : "eq",
            "filter_groups[0][filters][2][value]" : configurationId,
            "filter_groups[0][filters][3][key]" : "type_id",
            "filter_groups[0][filters][3][operator]" : "eq",
            "filter_groups[0][filters][3][value]" : typeId
        });
    }

    async showInputsForComposition(compositionId: number, boom_length_id: number|null, radius_id: number|null, pitch_id: number|null, roll_id: number|null, articulation_angle_id: number|null): Promise<SuccessResponse<InputModel>> {
        const parameters = {};

        if (boom_length_id) {
            Object.assign(parameters, { boom_length_id });
        }

        if (radius_id) {
            Object.assign(parameters, { radius_id });
        }

        if (pitch_id) {
            Object.assign(parameters, { pitch_id });
        }

        if (roll_id) {
            Object.assign(parameters, { roll_id });
        }

        if (articulation_angle_id) {
            Object.assign(parameters, { articulation_angle_id });
        }

        return await this.store(`compositions/${compositionId}/inputs`, "post", parameters);
    }
}
