var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"composition"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('h3',[_c('b-badge',{attrs:{"variant":_vm.form.model ? 'primary': 'secondary'}},[_vm._v("1")])],1)]),_c('b-col',{attrs:{"sm":"11","md":"11"}},[_c('b-form-group',{attrs:{"label":"Model","label-for":"form-input-name","label-cols":"3"}},[_c('validation-provider',{attrs:{"vid":"model","name":"Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.models.length == 0},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a model")]),_vm._l((_vm.models),function(model){return _c('b-form-select-option',{key:model.id,attrs:{"value":model}},[_vm._v(_vm._s(model.name))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('h3',[_c('b-badge',{attrs:{"variant":_vm.form.lmi ? 'primary': 'secondary'}},[_vm._v("2")])],1)]),_c('b-col',{attrs:{"sm":"11","md":"11"}},[_c('b-form-group',{attrs:{"label":"LMI","label-for":"form-input-name","label-cols":"3"}},[_c('validation-provider',{attrs:{"vid":"lmi","name":"LMI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.lmis.length == 0},model:{value:(_vm.form.lmi),callback:function ($$v) {_vm.$set(_vm.form, "lmi", $$v)},expression:"form.lmi"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select an LMI")]),_vm._l((_vm.lmis),function(lmi){return _c('b-form-select-option',{key:lmi.id,attrs:{"value":lmi}},[_vm._v(_vm._s(lmi.name))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('h3',[_c('b-badge',{attrs:{"variant":_vm.form.configuration ? 'primary': 'secondary'}},[_vm._v("3")])],1)]),_c('b-col',{attrs:{"sm":"11","md":"11"}},[_c('b-form-group',{attrs:{"label":"Configuration","label-for":"form-input-name","label-cols":"3"}},[_c('validation-provider',{attrs:{"vid":"configuration","name":"Configuration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.configurations.length == 0},model:{value:(_vm.form.configuration),callback:function ($$v) {_vm.$set(_vm.form, "configuration", $$v)},expression:"form.configuration"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a configuration")]),_vm._l((_vm.configurations),function(configuration){return _c('b-form-select-option',{key:configuration.id,attrs:{"value":configuration}},[_vm._v(_vm._s(_vm.getConfigurationNameDescription(configuration.name)))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('h3',[_c('b-badge',{attrs:{"variant":_vm.form.type ? 'primary': 'secondary'}},[_vm._v("4")])],1)]),_c('b-col',{attrs:{"sm":"11","md":"11"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"form-input-name","label-cols":"3"}},[_c('validation-provider',{attrs:{"vid":"type","name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.types.length == 0},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a type")]),_vm._l((_vm.types),function(type){return _c('b-form-select-option',{key:type.id,attrs:{"value":type}},[_vm._v(_vm._s(type.name))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{staticClass:"text-center mx-auto",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"composition__hero d-print-none d-none d-md-block"},[_c('b-img-lazy',_vm._b({staticClass:"img-fluid",attrs:{"src":_vm.heroUrl,"rounded":""}},'b-img-lazy',{ blank: true, blankColor: '#e2e2e7', width: 600, height: 348, class: 'm1' },false))],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }