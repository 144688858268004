












































































import Vue from "vue";
import { extend } from "vee-validate";
import {required} from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import CompositionService from "@/services/composition";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";
import ModelModel from "@/interfaces/Models/model";
import LMIModel from "@/interfaces/Models/lmi";
import ConfigurationModel from "@/interfaces/Models/configuration";
import TypeModel from "@/interfaces/Models/type";
import CompositionModel from "@/interfaces/Models/composition";
import ConfigurationName, {ConfigurationNameDescription} from "@/enums/configurationName";

for (const [rule, validation] of Object.entries({ required })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "Composition",
    created() {
        this.$emit('overlay', true);

        CompositionService.showModels().then(value => {
            this.models = value.response;
            this.$emit('overlay', false);
        }).catch((reason: FailureResponse)  => {
            if (reason.type == ErrorType.Validation && reason.response?.data) {
                this.observer.setErrors(reason.response?.data);
            } else {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            }
            this.$emit('overlay', false);
        });
    },
    data() {
        return {
            form: {
                model: null as ModelModel | null,
                lmi: null as LMIModel | null,
                configuration: null as ConfigurationModel | null,
                type: null as TypeModel | null,
                composition: null as CompositionModel | null,
            },
            models: [] as ModelModel[],
            lmis: [] as LMIModel[],
            configurations: [] as ConfigurationModel[],
            types: [] as TypeModel[],
        };
    },
    methods: {
        onSubmit() {
            console.log("onSubmit");
        },
        initLMI: function(model: ModelModel | null) {
            this.lmis = [];

            if (model) {
                this.$emit('overlay', true);

                CompositionService.showLMIForModel(model.id).then(value => {
                    this.lmis = value.response;

                    if (!this.lmis.find(({ code }) => code === this.form.lmi?.code)) {
                        this.form.lmi = null;
                    }

                    this.$emit('overlay', false);
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }
                    this.$emit('overlay', false);
                });
            }
        },
        initConfiguration: function(model: ModelModel | null, lmi: LMIModel | null) {
            this.configurations = [];

            if (model && lmi) {
                this.$emit('overlay', true);

                CompositionService.showConfigurationsForModelWithinLMI(model.id, lmi.id).then(value => {
                    this.configurations = value.response;

                    if (!this.configurations.find(({ id }) => id === this.form.configuration?.id)) {
                        this.form.configuration = null;
                    }

                    this.$emit('overlay', false);
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.$emit('overlay', false);
                });
            }
        },
      initType: function(model: ModelModel | null, lmi: LMIModel | null, configuration: ConfigurationModel | null) {
        this.types = [];

        if (model && lmi && configuration) {
          this.$emit('overlay', true);

          CompositionService.showTypesForModelWithinLMIConfiguration(model.id, lmi.id, configuration.id).then(value => {
            this.types = value.response;

            if (!this.types.find(({ id }) => id === this.form.type?.id)) {
              this.form.type = null;
            }

            this.$emit('overlay', false);
          }).catch((reason: FailureResponse)  => {
            if (reason.type == ErrorType.Validation && reason.response?.data) {
              this.observer.setErrors(reason.response?.data);
            } else {
              this.$notify({
                type: "error",
                title: this.$appNotificationErrorTitle,
                text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
              });
            }

            this.$emit('overlay', false);
          });
        }
      },
        initComposition: function(model: ModelModel | null, lmi: LMIModel | null, configuration: ConfigurationModel | null, type: TypeModel | null) {
            this.form.composition = null;

            if (model && lmi && configuration && type) {
                this.$emit('overlay', true);

                CompositionService.showForModelLMIConfigurationType(model.id, lmi.id, configuration.id, type.id).then(value => {
                    value.response.model = model;
                    this.form.composition = value.response;

                    this.$emit('overlay', false);
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.$emit('overlay', false);
                });
            }
        },
        getConfigurationNameDescription(configurationName: ConfigurationName): string {
            const description = ConfigurationNameDescription.get(configurationName);
            if (description) {
                return description;
            }

            return configurationName;
        },
    },
    computed: {
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        },

        heroUrl(): string {
            if (this.form.model && this.form.model.hero_url) {
                return this.form.model.hero_url;
            }

            return require('@/assets/model-no-image.jpg');
        }
    },
    watch: {
        "form.model": function (model: ModelModel | null) {
            this.initLMI(model);
            this.initConfiguration(model, this.form.lmi);
            this.initType(model, this.form.lmi, this.form.configuration);
            this.initComposition(model, this.form.lmi, this.form.configuration, this.form.type);
        },
        "form.lmi": function (lmi: LMIModel | null) {
            this.initConfiguration(this.form.model, lmi);
            this.initType(this.form.model, lmi, this.form.configuration);
            this.initComposition(this.form.model, lmi, this.form.configuration, this.form.type);
        },
        "form.configuration": function (configuration: ConfigurationModel | null) {
           this.initType(this.form.model, this.form.lmi, configuration);
           this.initComposition(this.form.model, this.form.lmi, configuration, this.form.type);
        },
      "form.type": function (type: TypeModel | null) {
        this.initComposition(this.form.model, this.form.lmi, this.form.configuration, type);
      },
        "form.composition": function (composition: CompositionModel | null) {
            this.$emit('composition', composition);
        }
    }
});
