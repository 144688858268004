









































































import Vue from "vue";
import moment from "moment";

export default Vue.extend({
    name: "Footer",
    computed: {

        date(): string {
            return  moment(Date()).format('YYYY')
        }
    }
});
