var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputs"},[(_vm.composition)?[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('h2',{staticClass:"page-header h4"},[_vm._v("Inputs")]),_c('b-row',[_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Radius (m)","label-for":"form-input-radius"}},[_c('validation-provider',{attrs:{"vid":"radius","name":"Radius","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.radius.length == 0},model:{value:(_vm.form.radius),callback:function ($$v) {_vm.$set(_vm.form, "radius", $$v)},expression:"form.radius"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a radius")]),_vm._l((_vm.radius),function(_radius){return _c('b-form-select-option',{key:_radius.id,attrs:{"value":_radius}},[_vm._v(_vm._s(_radius.value))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Boom Length (m)","label-for":"form-input-boom-length"}},[_c('validation-provider',{attrs:{"vid":"boom_length","name":"Boom Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.boomLengths.length == 0},model:{value:(_vm.form.boomLength),callback:function ($$v) {_vm.$set(_vm.form, "boomLength", $$v)},expression:"form.boomLength"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a boom length")]),_vm._l((_vm.boomLengths),function(boomLength){return _c('b-form-select-option',{key:boomLength.id,attrs:{"value":boomLength}},[_vm._v(_vm._s(boomLength.value))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Pitch (deg)","label-for":"form-input-pitch"}},[_c('validation-provider',{attrs:{"vid":"pitch","name":"Pitch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.pitches.length == 0},model:{value:(_vm.form.pitch),callback:function ($$v) {_vm.$set(_vm.form, "pitch", $$v)},expression:"form.pitch"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a pitch")]),_vm._l((_vm.pitches),function(pitch){return _c('b-form-select-option',{key:pitch.id,attrs:{"value":pitch}},[_vm._v(_vm._s(pitch.value))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Roll (deg)","label-for":"form-input-roll"}},[_c('validation-provider',{attrs:{"vid":"roll","name":"Roll","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.rolls.length == 0},model:{value:(_vm.form.roll),callback:function ($$v) {_vm.$set(_vm.form, "roll", $$v)},expression:"form.roll"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select a roll")]),_vm._l((_vm.rolls),function(roll){return _c('b-form-select-option',{key:roll.id,attrs:{"value":roll}},[_vm._v(_vm._s(roll.value))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Articulation Angle (deg)","label-for":"form-input-articulation_angle"}},[_c('validation-provider',{attrs:{"vid":"articulationAngle","name":"Articulation Angle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"disabled":_vm.articulationAngles.length == 0},model:{value:(_vm.form.articulationAngle),callback:function ($$v) {_vm.$set(_vm.form, "articulationAngle", $$v)},expression:"form.articulationAngle"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Please select an articulation angle")]),_vm._l((_vm.articulationAngles),function(articulationAngle){return _c('b-form-select-option',{key:articulationAngle.id,attrs:{"value":articulationAngle}},[_vm._v(_vm._s(articulationAngle.value))])})],2),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":_vm.isGraphic ? 6 : 4,"md":_vm.isGraphic ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Load (Kg)","label-for":"form-input-load"}},[_c('validation-provider',{attrs:{"vid":"load","name":"Load","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-load","type":"text","state":errors[0] ? false : null,"required":"","placeholder":"Load"},model:{value:(_vm.form.load),callback:function ($$v) {_vm.$set(_vm.form, "load", $$v)},expression:"form.load"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',[_c('b-button',{staticClass:"d-print-none btn-block",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Calculate")]),_c('b-button',{staticClass:"d-print-none btn-block mb-1",attrs:{"type":"reset","variant":"secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v("Reset Inputs")])],1)],1)]}}],null,false,3344192174)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }