/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import CalculatorModel from "@/interfaces/Models/calculator";


export default new class extends StackExchangeService {
    async run(composition_id: number, boom_length_id: number, radius_id: number, pitch_id: number, roll_id: number, articulation_angle_id: number, load: number): Promise<SuccessResponse<CalculatorModel>> {
        return await this.store("calculator/run", "post", {
            composition_id,
            boom_length_id,
            radius_id,
            pitch_id,
            roll_id,
            articulation_angle_id,
            load
        });
    }
}
