




















import Vue from "vue";

export default Vue.extend({
    name: "Content",
    props: {
        title: String,
        subTitle: String
    },
});
