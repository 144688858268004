enum ConfigurationName {
    FB = "FB",
    RH20 = "RH20",
    FLUG = "FLUG",
    LUG1 = "LUG1",
    RR1H = "RR1H",
    RR1S = "RR1S",
    RR2H = "RR2H",
    RR2S = "RR2S",
    RR3H = "RR3H",
    RR3S = "RR3S",
}

export const ConfigurationNameDescription = new Map<string, string>([
    [ConfigurationName.FB, "Fall Block"],
    [ConfigurationName.RH20, "Rhino Hook 20t"],
    [ConfigurationName.FLUG, "Fixed Lug"],
    [ConfigurationName.LUG1, "Lug 1"],
    [ConfigurationName.RR1H, "Runner 1 (10° offset) Hook"],
    [ConfigurationName.RR1S, "Runner 1 (10° offset) Sheave"],
    [ConfigurationName.RR2H, "Runner 1 (25° offset) Hook"],
    [ConfigurationName.RR2S, "Runner 1 (25° offset) Sheave"],
    [ConfigurationName.RR3H, "Runner 3 (30° offset) Hook"],
    [ConfigurationName.RR3S, "Runner 3 (30° offset) Sheave"]
])

export default ConfigurationName;
