

















































































import Vue from "vue";
import { extend } from "vee-validate";
// eslint-disable-next-line @typescript-eslint/camelcase
import { required, is_not,  min_value } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import CompositionService from "@/services/composition";
import CalculatorService from "@/services/calculator";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";
import CompositionModel from "@/interfaces/Models/composition";
import PitchModel from "@/interfaces/Models/pitch";
import RollModel from "@/interfaces/Models/roll";
import CalculatorModel from "@/interfaces/Models/calculator";
import ArticulationAngleModel from "@/interfaces/Models/articulationAngle";
import BoomLengthModel from "@/interfaces/Models/boomLength";
import RadiusModel from "@/interfaces/Models/radius";


// eslint-disable-next-line @typescript-eslint/camelcase
for (const [rule, validation] of Object.entries({ required, is_not,  min_value })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "Inputs",
    props: {
        composition: Object
    },
    data() {
        return {
            form: {
                pitch: null as PitchModel | null,
                roll: null as RollModel | null,
                articulationAngle: null as ArticulationAngleModel | null,
                boomLength: null as BoomLengthModel | null,
                radius: null as RadiusModel | null,
                load: null as number | null,
                calculator: null as CalculatorModel | null,
                isReloading: false
            },
            pitches: [] as PitchModel[],
            rolls: [] as RollModel[],
            articulationAngles: [] as ArticulationAngleModel[],
            boomLengths: [] as BoomLengthModel[],
            radius: [] as RadiusModel[]
        };
    },
    methods: {
        initInput: function() {
            this.pitches = [];
            this.rolls = [];
            this.articulationAngles = [];
            this.boomLengths = [];
            this.radius = [];
            this.form.calculator = null;

            if (this.composition) {
                this.form.isReloading = true;

                this.$emit('overlay', true);

                CompositionService.showInputsForComposition(this.composition.id,
                    (this.form.boomLength ? this.form.boomLength.id : null),
                    (this.form.radius ? this.form.radius.id : null),
                    (this.form.pitch ? this.form.pitch.id : null),
                    (this.form.roll ? this.form.roll.id : null),
                    (this.form.articulationAngle ? this.form.articulationAngle.id : null)
                ).then(value => {
                    if (value.response) {
                        if (value.response.boom_lengths) {
                            this.boomLengths = value.response.boom_lengths.sort((a, b) => {
                                if (a.value < b.value) {
                                    return -1;
                                }
                                if (a.value > b.value) {
                                    return 1;
                                }

                                return 0;
                            });

                            if (!this.boomLengths.find(({ id }) => id === this.form.boomLength?.id)) {
                                this.form.boomLength = null;
                            }
                        } else {
                            this.form.boomLength = null;
                        }

                        if (value.response.radius) {
                            this.radius = value.response.radius.sort((a, b) => {
                                if (a.value < b.value) {
                                    return -1;
                                }
                                if (a.value > b.value) {
                                    return 1;
                                }

                                return 0;
                            });

                            if (!this.radius.find(({ id }) => id === this.form.radius?.id)) {
                                this.form.radius = null;
                            }
                        } else {
                            this.form.radius = null;
                        }

                        if (value.response.pitches) {
                            this.pitches = value.response.pitches.sort((a, b) => {
                                if (a.value < b.value) {
                                    return -1;
                                }
                                if (a.value > b.value) {
                                    return 1;
                                }

                                return 0;
                            });

                            if (!this.pitches.find(({ id }) => id === this.form.pitch?.id)) {
                                this.form.pitch = null;
                            }
                        } else {
                            this.form.pitch = null;
                        }

                        if (value.response.rolls) {
                            this.rolls = value.response.rolls.sort((a, b) => {
                                if (a.value < b.value) {
                                    return -1;
                                }
                                if (a.value > b.value) {
                                    return 1;
                                }

                                return 0;
                            });

                            if (!this.rolls.find(({ id }) => id === this.form.roll?.id)) {
                                this.form.roll = null;
                            }
                        } else {
                            this.form.roll = null;
                        }

                        if (value.response.articulation_angles) {
                            this.articulationAngles = value.response.articulation_angles.sort((a, b) => {
                                if (a.value < b.value) {
                                    return -1;
                                }
                                if (a.value > b.value) {
                                    return 1;
                                }

                                return 0;
                            });

                            if (!this.articulationAngles.find(({ id }) => id === this.form.articulationAngle?.id)) {
                                this.form.articulationAngle = null;
                            }
                        } else {
                            this.form.articulationAngle = null;
                        }

                    } else {
                        this.form.boomLength = null;
                        this.form.radius = null;
                        this.form.pitch = null;
                        this.form.roll = null;
                        this.form.articulationAngle = null;
                    }

                    this.form.isReloading = false;
                    this.$emit('overlay', false);
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.form.isReloading = false;
                    this.$emit('overlay', false);
                });
            }

        },

        onSubmit() {
            if (this.composition && this.form.pitch && this.form.roll && this.form.articulationAngle && this.form.boomLength && this.form.radius && this.form.load) {
                this.$emit('overlay', true, true);

                CalculatorService.run(this.composition.id,
                    this.form.boomLength.id, this.form.radius.id,
                    this.form.pitch.id, this.form.roll.id,
                    this.form.articulationAngle.id, this.form.load
                ).then(value => {
                    this.form.calculator = value.response;
                }).catch((reason: FailureResponse)  => {
                    this.form.calculator = null

                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.$emit('overlay', false);
                });
            }
        },
        onReset() {
            this.form.isReloading = true;
            this.form.boomLength = null;
            this.form.radius = null;
            this.form.pitch = null;
            this.form.roll = null;
            this.form.articulationAngle = null;
            this.form.load = null;

            this.initInput();
        }
    },
    computed: {
        isGraphic(): boolean {
            if (this.composition && this.composition.graphic_url) {
                return true;
            }

            return false;
        },
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    },
    watch: {
        composition: function (composition: CompositionModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.boomLength": function (boomLength: BoomLengthModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.radius": function (radius: RadiusModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.pitch": function (pitch: PitchModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.roll": function (roll: RollModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.articulationAngle": function (articulationAngle: ArticulationAngleModel | null) {
            if (!this.form.isReloading){
                this.initInput();
            }
        },
        "form.calculator": function (calculator: CalculatorModel | null) {
            this.$emit('calculator', calculator);
        }
    }
});
