enum ErrorType {
    Fatal = "A fatal error occurred.",
    Authentication = "An error occurred while using the credentials.",
    Authorization = "This action is not authorized.",
    Decoding = "An error occurred while decoding data.",
    Validation = "An error occurred while validating data.",
    NotFound = "An error occurred while fetching data.",
}

export default ErrorType;
