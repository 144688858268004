


























import Vue from "vue";
import Layout from "@/components/Layout.vue";
import Composition from "@/components/Composition.vue";
import Inputs from "@/components/Inputs.vue";
import Result from "@/components/Result.vue";
import Utilisation from "@/components/Utilisation.vue";
import CompositionModel from "@/interfaces/Models/composition";
import CalculatorModel from "@/interfaces/Models/calculator";

export default Vue.extend({
    name: "Home",
    components: {
        Layout,
        Composition,
        Inputs,
        Result,
        Utilisation
    },
    data() {
        return {
            composition: null as CompositionModel | null,
            calculator: null as CalculatorModel | null,
            overlay: {
                show: false,
                custom: false
            },
        }
    },
    methods: {
        onComposition(composition: CompositionModel | null) {
            this.composition = composition;
        },
        onInputs(calculator: CalculatorModel | null) {
           this.calculator = calculator;
        },
        onOverlay(show: boolean, custom = false) {
            this.overlay.show = show;
            this.overlay.custom = custom;
        }
    },
    computed: {
        graphicUrl(): string|null {
            if (this.composition && this.composition.graphic_url) {
                return this.composition.graphic_url;
            }

            return null;
        }
    }
});
