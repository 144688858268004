


















import Vue from "vue";
import CalculatorModel from "@/interfaces/Models/calculator";

export default Vue.extend({
    name: "Utilisation",
    props: {
        calculator: Object
    },
    data() {
        return {
            utilisation: {
                max: 110,
                variant: "primary"
            },
            data: null as CalculatorModel | null,
        }
    },
    watch: {
        calculator: function (calculator: CalculatorModel | null) {
            this.data = calculator

            this.utilisation.variant = "primary";
            if (this.data) {
                if (this.data.utilisation) {
                    if (this.data.utilisation > 90 && this.data.utilisation <= 100) {
                        this.utilisation.variant = "warning";
                    } else if (this.data.utilisation > 100) {
                        this.utilisation.variant = "danger";
                    }
                }
                this.$emit("overlay", false);
            }
        }
    }
});
