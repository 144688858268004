












import Vue from "vue";
import Header from "@/components/Header.vue";
import Quote from "@/components/Quote.vue";
import Content from "@/components/Content.vue";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
    name: "Layout",
    components: {
        Header,
        Quote,
        Content,
        Footer
    },
    props: {
        title: String,
        subTitle: String
    },
});
