





































import Vue from "vue";
import CalculatorModel from "@/interfaces/Models/calculator";

export default Vue.extend({
    name: "Result",
    props: {
        calculator: Object
    },
    data() {
        return {
            data: null as CalculatorModel | null,
        }
    },
    methods: {
        onPrint() {
            window.print();
        }
    },
    watch: {
        calculator: function (calculator: CalculatorModel | null) {
            this.data = calculator
            if (calculator) {
                this.$emit("overlay", false);
            }
        }
    }
});
